import {Component, OnInit, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MoneyPinReceiptClass, PinInfoClass} from '../../models/moneypin';
import {MoneyPinService} from '../../services/money-pin/money-pin.service';

import html2pdf from 'html2pdf.js';
declare var $;

@Component({
  selector: 'app-moneypinreceipt',
  templateUrl: './moneypinreceipt.component.html',
  styleUrls: ['./moneypinreceipt.component.scss']
})
export class MoneypinreceiptComponent implements OnInit {
  moneypin = new MoneyPinReceiptClass();
  pinInfo = new PinInfoClass();

  constructor(private router: Router, private route: ActivatedRoute, private moneypinService: MoneyPinService,
              private zone: NgZone) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.trxid) {
        this.pinInfo.pin_redeem_tranx_id = params.trxid;
        this.moneypin.transactionID = params.trxid;
        this.getReceiptInfo();
        this.getReceiptAddInfo();
      }
    });
  }

  getReceiptInfo() {
    this.moneypinService.getReceiptInfo(this.pinInfo).then((data) => {
      if (data && data.data && data.data.output) {
        const data2 = data.data.output;
        // console.log(data2);
        this.zone.run(() => {
          const exchangerate = data2.w_exchange_rate;
          let exchangeratearray = [];
          exchangeratearray = exchangerate.split('<br>');
          // console.log(exchangeratearray);
          this.moneypin.bank = data2.bankname;
          this.moneypin.bank_accNo = data2.ben_collectbankacctno;
          this.moneypin.recipient_name = data2.ben_name;
          this.moneypin.remit_name = data2.crc_name;
          this.moneypin.exchange_rate = exchangeratearray[0];
          this.moneypin.exchange_rate2 = exchangeratearray[1];
          this.moneypin.transactionDate = data2.w_masa_kirim;
          this.moneypin.ringgitMalaysia = Number(data2.rmtapp_amt);
          this.moneypin.malaysia_currency_code = 'RM';
          this.moneypin.indo_rupiah = Number(data2.rmtcur_amt);
          this.moneypin.indo_currency_code = data2.rmtcur_currency;
        });
      } else {
        this.moneypin = new MoneyPinReceiptClass();
        swal({
          text: 'No records found.',
          icon: 'warning'
        });
      }
    }).catch((err) => {
      this.moneypin = new MoneyPinReceiptClass();
      swal({
        text: err.message,
        icon: 'error'
      });
    });
  }

  getReceiptAddInfo() {
    this.moneypinService.getReceiptAddInfo(this.pinInfo).then((data) => {
      if (data && data.data && data.data.output) {
        const data2 = data.data.output;
        this.zone.run(() => {
          this.moneypin.card_no = data2.card_no;
          this.moneypin.phone = data2.app_phone;
          this.moneypin.money_pin_charge = Number(data2.charge);
        });
      } else {
        this.moneypin.card_no = '';
        this.moneypin.phone = '';
        this.moneypin.money_pin_charge = 0;
        swal({
          text: 'No records found.',
          icon: 'warning'
        });
      }
    }).catch((err) => {
      this.moneypin.card_no = '';
      this.moneypin.phone = '';
      this.moneypin.money_pin_charge = 0;
      swal({
        text: err.message,
        icon: 'error'
      });
    });
  }

  print() {
    // const divContents = $('#receipt').html();
    // const printWindow = window.open('', '', 'height=400,width=1000');
    // printWindow.document.write('<html><head><title>Receipt</title>');
    // // printWindow.document.write('<link href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.css" rel="stylesheet">\n' +
    // //   '  <link href="https://cdn.datatables.net/1.10.19/css/dataTables.bootstrap4.min.css" rel="stylesheet">');
    // printWindow.document.write('</head><body style="width: 100%">');
    // printWindow.document.write(divContents);
    // // printWindow.document.write('<script src="https://code.jquery.com/jquery-3.3.1.js"></script>\n' +
    // //   '<script src="https://cdn.datatables.net/1.10.19/js/jquery.dataTables.min.js"></script>\n' +
    // //   '<script src="https://cdn.datatables.net/1.10.19/js/dataTables.bootstrap4.min.js"></script>');
    // printWindow.document.write('</body></html>');
    // printWindow.document.close();
    // printWindow.print();

    var element = document.getElementById('receipt');
    var opt = {
      margin: [20,10,10,-35], //[vMargin, hMargin], or [top, left, bottom, right]
      filename:     'Receipt.pdf',
      // image:        { type: 'jpeg', quality: 0.98 },
      // html2canvas:  { scale: 2 },
      // jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf(element, opt);
  }
}
