import {environment} from '../../environments/environment';

export const APPCONFIG = {
  headerTitle: 'Mencetak Resit',
  country: 'MY',
  port: '84',
  logo: 'http://mobile-money.com/img/logo.png',
  year: new Date().getFullYear(),
  privacypolicy: '',
  company: 'Mobile Money International Sdn. Bhd.',
  environment: 'prod',   // prod //test
  basedUrl: {
    dev: environment.APIEndpoint || '/api/', // 'http://localhost:8765/api/',
    test: environment.APIEndpoint || '/api/', // 'http://192.169.2.198:8000/api/',
    prod: environment.APIEndpoint || '/api/', // 'https://indoreceipt.mobile-money.com.my/api/'
  },
};
