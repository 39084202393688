import {Component, OnInit, NgZone} from '@angular/core';
import {SearchClass} from '../../models/search';
import {OtherCounrtyMPinClass} from '../../models/moneypin';
import {MoneyPinService} from '../../services/money-pin/money-pin.service';


@Component({
  selector: 'app-other-country-main-moneypin',
  templateUrl: './other-country-main-moneypin.component.html',
  styleUrls: ['./other-country-main-moneypin.component.scss']
})
export class OtherCountryMainMoneypinComponent implements OnInit {
  displaySearchMPin = '';
  displaySearchMobile = '';
  searchkey = new SearchClass();
  totalNoRecord = 'record(s) found.';
  pinlist = [];

  constructor(private moneypinService: MoneyPinService, private zone: NgZone) {
  }

  ngOnInit() {
  }

  search() {
    this.pinlist = [];
    if (this.validate()) {
      this.moneypinService.getOtherCountryPinInfo(this.searchkey).then((data) => {
        if (data && data.data) {
          // console.log(typeof data);
          // console.log(data);
          const data2 = data.data;
          this.zone.run(() => {
            this.pinlist = [];
            if (data2 && Number(data2.returnValue) === 0 && data2.recordset && data2.recordset.length > 0) {
              for (let i = 0; i < data2.recordset.length; i++) {
                const row = data2.recordset[i];
                const d = new OtherCounrtyMPinClass();
                d.trans_id = Number(row.trans_id);
                if (row.pinno) {
                  d.pinno = row.pinno;
                } else {
                  d.pinno = this.searchkey.keyword1;
                }
                d.CreatedDatetime = row.CreatedDatetime;
                d.SenderName = row.SenderName;
                d.SenderMobile = row.SenderMobile;
                d.ReceiverName = row.ReceiverName;
                d.BankID = row.BankID ? row.BankID : 'Cash Disbursement';
                d.BankName = row.bankname ? row.bankname : 'Cash Disbursement';
                d.BankAccountNumber = row.BankAccountNumber ? row.BankAccountNumber : 'Cash Disbursement';
                d.mm_charged_amt = Number(row.mm_charged_amt);
                d.mm_trans_fee = Number(row.mm_trans_fee);
                d.mm_exchange_rate = Number(row.mm_exchange_rate);
                d.payout_amt = Number(row.payout_amt);
                d.payout_currency = row.payout_currency;
                d.invoice_no = row.invoice_no;
                d.tax_amt = Number(row.tax_amt);
                d.status = row.status;
                d.country = row.country;
                d.remit_provider_id = Number(row.remit_provider_id);
                d.remit_provider_desc = row.remit_provider_desc;
                d.req_source = Number(row.req_source);
                d.req_source_desc = row.req_source_desc;

                // CANCEL, DECLINED, ERROR_SENDTXN, HIT_MAX_RETRY, MISSMATCH_NAME, REVERSED
                if (row.status) {
                  if (row.status.trim().toUpperCase() === 'CANCEL') {
                    d.showButton = false;
                    d.noButtonMsg = 'This money pin is cancelled, cannot print receipt anymore.';
                  } else if (row.status.trim().toUpperCase() === 'DECLINED') {
                    d.showButton = false;
                    d.noButtonMsg = 'This money pin is declined, cannot print receipt anymore.';
                  } else if (row.status.trim().toUpperCase() === 'ERROR_SENDTXN') {
                    d.showButton = false;
                    d.noButtonMsg = 'This money pin has an error, cannot print receipt anymore.';
                  } else if (row.status.trim().toUpperCase() === 'HIT_MAX_RETRY') {
                    d.showButton = false;
                    d.noButtonMsg = 'This money pin has hit the max retry, cannot print receipt anymore.';
                  } else if (row.status.trim().toUpperCase() === 'MISSMATCH_NAME') {
                    d.showButton = false;
                    d.noButtonMsg = 'This money pin is mismatched, cannot print receipt anymore.';
                  } else if (row.status.trim().toUpperCase() === 'REVERSED') {
                    d.showButton = false;
                    d.noButtonMsg = 'This money pin is reversed, cannot print receipt anymore.';
                  }
                } else { // 2019-12-20, requested by steven, allow them to print even no status
                  d.showButton = true;  // false
                  d.noButtonMsg = 'UNPAID'; // UNKNOWN STATUS
                }
                this.pinlist.push(d);
              }
            } else {
              swal({
                text: data2.output.err_desc || 'Invalid Pin Number or Sender Mobile.',
                icon: 'error'
              });
            }
          });
        } else {
          swal({
            text: 'No records found.',
            icon: 'warning'
          });
        }
      }).catch((err) => {
        swal({
          text: err.message,
          icon: 'error'
        });
      });
    }
  }

  validate(): boolean {
    this.displaySearchMobile = '';
    this.displaySearchMPin = '';
    if (this.searchkey.keyword1 === '') {
      this.displaySearchMPin = 'No. MoneyPIN required.';
    }

    if (this.searchkey.keyword2 === '') {
      this.displaySearchMobile = 'No. HP required.';
    }

    if (this.searchkey.keyword1 === '' || this.searchkey.keyword2 === '') {
      return false;
    }
    return true;
  }

  print(p) {
    let link = window.location.href;
    link = link.replace('other', 'oreceipt');
    link = link + '?pin=' + p.pinno.toString() + '&sender=' + p.SenderMobile;
    window.open(link, '_blank'); // , 'location=yes'
  }
}
