import {Component, OnInit, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OtherCounrtyMPinClass} from '../../models/moneypin';
import {MoneyPinService} from '../../services/money-pin/money-pin.service';
import {SearchClass} from '../../models/search';

import html2pdf from 'html2pdf.js';

// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// declare var require: any
// const FileSaver = require('file-saver');

declare var $;

@Component({
  selector: 'app-othermoneypinreceipt',
  templateUrl: './othermoneypinreceipt.component.html',
  styleUrls: ['./othermoneypinreceipt.component.scss']
})
export class OthermoneypinreceiptComponent implements OnInit {
  moneypin = new OtherCounrtyMPinClass();
  searchkey = new SearchClass();

  constructor(private router: Router, private route: ActivatedRoute, private moneypinService: MoneyPinService,
              private zone: NgZone) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.pin && params.sender) {
        this.moneypin.pinno = params.pin;
        this.moneypin.SenderMobile = params.sender;
        this.searchkey.keyword1 = params.pin;
        this.searchkey.keyword2 = params.sender;

        this.getOtherCountryPinInfo('pin');
      }

      if (params.trxid) {
        this.searchkey.intkeyword1 = Number(params.trxid);
        this.moneypin.trans_id = Number(params.trxid);

        this.getOtherCountryPinInfo('');
      }
    });
  }

  getOtherCountryPinInfo(searchby) {

    this.moneypinService.getOtherCountryPinInfo(this.searchkey).then((data) => {
      if (data && data.data) {
        const data2 = data.data;
        this.zone.run(() => {
          if (data2 && Number(data2.returnValue) === 0 && data2.recordset && data2.recordset.length > 0) {
            const row = data2.recordset[0];

            this.moneypin.trans_id = Number(row.trans_id);
            if (row.pinno) {
              this.moneypin.pinno = row.pinno;
            } else {
              this.moneypin.pinno = this.searchkey.keyword1;
            }
            this.moneypin.CreatedDatetime = row.CreatedDatetime;
            this.moneypin.SenderName = row.SenderName;
            this.moneypin.SenderMobile = row.SenderMobile;
            this.moneypin.ReceiverName = row.ReceiverName;
            this.moneypin.BankID = row.BankID ? row.BankID : 'Cash Disbursement';
            this.moneypin.BankName = row.bankname ? row.bankname : 'Cash Disbursement';
            this.moneypin.BankAccountNumber = row.BankAccountNumber ? row.BankAccountNumber : 'Cash Disbursement';
            this.moneypin.mm_charged_amt = Number(row.mm_charged_amt);
            this.moneypin.mm_trans_fee = Number(row.mm_trans_fee);
            this.moneypin.mm_exchange_rate = Number(row.mm_exchange_rate);
            this.moneypin.payout_amt = Number(row.payout_amt);
            this.moneypin.payout_currency = row.payout_currency;
            this.moneypin.invoice_no = row.invoice_no;
            this.moneypin.tax_amt = Number(row.tax_amt);
            this.moneypin.status = row.status ? row.status : 'UNPAID';
            this.moneypin.country = row.country;
            this.moneypin.remit_provider_id = Number(row.remit_provider_id);
            this.moneypin.remit_provider_desc = row.remit_provider_desc;
            this.moneypin.req_source = Number(row.req_source);
            this.moneypin.req_source_desc = row.req_source_desc;
            this.moneypin.SenderCardNo = row.SenderCardNo;

          } else {
            this.moneypin = new OtherCounrtyMPinClass();
            swal({
              text: data2.output.err_desc || 'Invalid Pin Number or Sender Mobile.',
              icon: 'error'
            });
          }
        });
      } else {
        this.moneypin = new OtherCounrtyMPinClass();
        swal({
          text: 'No records found.',
          icon: 'warning'
        });
      }
    }).catch((err) => {
      this.moneypin = new OtherCounrtyMPinClass();
      swal({
        text: err.message,
        icon: 'error'
      });
    });
  }

  print() {
    // const divContents = $('#receipt').html();
    // const printWindow = window.open('', '', 'height=400,width=1000');
    // printWindow.document.write('<html><head><title>Receipt</title>');
    // printWindow.document.write('</head><body style="width: 100%">');
    // printWindow.document.write(divContents);
    // printWindow.document.write('</body></html>');
    // printWindow.document.close();
    // printWindow.print();


    // var doc = new jsPDF();
    // doc.autoTable({html: '#receipt'});
    // doc.save('table.pdf');

    var element = document.getElementById('receipt');
    var opt = {
      margin: [20,10,10,-35], //[vMargin, hMargin], or [top, left, bottom, right]
      filename:     'Receipt.pdf',
      // image:        { type: 'jpeg', quality: 0.98 },
      // html2canvas:  { scale: 2 },
      // jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf(element, opt);


    // const pdfUrl = './assets/sample.pdf';
    // const pdfName = 'your_pdf_file';
    // FileSaver.saveAs(pdfUrl, pdfName);

  }
}
