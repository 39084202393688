import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MoneypinreceiptComponent} from './pages/moneypinreceipt/moneypinreceipt.component';
import {MainmoneypinComponent} from './pages/mainmoneypin/mainmoneypin.component';
import {Error404Component} from './pages/error404/error404.component';

import {WebapiService} from './services/webapi/webapi.service';
import {MoneyPinService} from './services/money-pin/money-pin.service';
import {UtilsService} from './services/utils/utils.service';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { OtherCountryMainMoneypinComponent } from './pages/other-country-main-moneypin/other-country-main-moneypin.component';
import { OthermoneypinreceiptComponent } from './pages/othermoneypinreceipt/othermoneypinreceipt.component';


@NgModule({
  declarations: [
    AppComponent,
    MoneypinreceiptComponent,
    MainmoneypinComponent,
    Error404Component,
    MainMenuComponent,
    OtherCountryMainMoneypinComponent,
    OthermoneypinreceiptComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    WebapiService,
    MoneyPinService,
    UtilsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
