import {Component, OnInit, NgZone} from '@angular/core';
import {SearchClass} from '../../models/search';
import {PinInfoClass} from '../../models/moneypin';
import {MoneyPinService} from '../../services/money-pin/money-pin.service';

@Component({
  selector: 'app-mainmoneypin',
  templateUrl: './mainmoneypin.component.html',
  styleUrls: ['./mainmoneypin.component.scss']
})
export class MainmoneypinComponent implements OnInit {
  displaySearchMPin = '';
  displaySearchMobile = '';
  searchkey = new SearchClass();
  totalNoRecord = 'record(s) found.';
  pinlist = [];

  constructor(private moneypinService: MoneyPinService, private zone: NgZone) {
    // console.log('hahahha');
  }

  ngOnInit() {
  }


  search() {
    this.pinlist = [];
    if (this.validate()) {
      this.moneypinService.getPinInfo(this.searchkey).then((data) => {
        // console.log(data.data);
        if (data && data.data) {
          const data2 = data.data;
          this.zone.run(() => {
            this.pinlist = [];
            if (data2 && data2.recordset && data2.recordset.length > 0) {
              for (let i = 0; i < data2.recordset.length; i++) {
                const row = data2.recordset[i];
                const d = new PinInfoClass();
                d.pin_idx = Number(row.user_idx);
                d.pin_creation_date = row.pin_creation_date;
                d.pin_expiry_date = row.pin_expiry_date;
                d.pin_merchant_crc_id = Number(row.pin_merchant_crc_id);
                d.pin_number = row.pin_number.substr(0, 5);
                d.pin_type = Number(row.pin_type);
                d.pin_sequence_no = Number(row.pin_sequence_no);
                d.pin_sequence6_no = Number(row.pin_sequence6_no);
                d.pin_random_no = Number(row.pin_random_no);
                d.pin_owner_no = row.pin_owner_no;
                d.pin_tranx_id = Number(row.pin_tranx_id);
                d.pin_amount = Number(row.pin_amount);
                d.pin_mdr = Number(row.pin_mdr);
                d.pin_redeem_tranx_id = Number(row.pin_redeem_tranx_id);
                d.pin_redeem_date = row.pin_redeem_date;
                d.pin_redeem_HP = row.pin_redeem_HP;
                d.pin_status = Number(row.pin_status);
                d.pin_product_id = Number(row.pin_product_id);
                d.pin_flag = Number(row.pin_flag);
                d.card_id = row.card_id;
                d.pin_issue_channel = row.pin_issue_channel;
                d.ref_idx = row.ref_idx;

                d.c_number = row.c_number;
                d.pin_amount2 = Number(row.pin_amount2);
                d.PinType_Desc = row.PinType_Desc;
                d.PinStatus = Number(row.PinStatus);
                d.PinStatus_desc = row.PinStatus_desc;
                d.RedeemTxnId = Number(row.RedeemTxnId);

                if (row.PinStatus_desc.trim().toUpperCase() === 'BATAL') {
                  d.showButton = false;
                  d.noButtonMsg = 'Money Pin ini sudah dibatal, tak boleh cetak resit lagi.';
                } else if (row.PinStatus_desc.trim().toUpperCase() === 'BELUM GUNA') {
                  d.showButton = false;
                  d.noButtonMsg = 'Money Pin ini belum guna, sila balas rekening dulu.';
                }

                this.pinlist.push(d);
              }
            } else {
              swal({
                text: 'No records found.',
                icon: 'warning'
              });
            }
          });
        } else {
          swal({
            text: 'No records found.',
            icon: 'warning'
          });
        }
      }).catch((err) => {
        // console.log(err);
        swal({
          text: err.message,
          icon: 'error'
        });
      });
    }
  }

  validate(): boolean {
    this.displaySearchMobile = '';
    this.displaySearchMPin = '';
    if (this.searchkey.keyword1 === '') {
      this.displaySearchMPin = 'No. MoneyPIN required.';
    }

    if (this.searchkey.keyword2 === '') {
      this.displaySearchMobile = 'No. HP required.';
    }

    if (this.searchkey.keyword1 === '' || this.searchkey.keyword2 === '') {
      return false;
    }
    return true;
  }

  print(p) {
    let link = window.location.href;
    link = link.replace('indo2', 'receipt');
    link = link + '?trxid=' + p.pin_redeem_tranx_id.toString();
    window.open(link, '_blank'); // , 'location=yes'
  }
}
