import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  getBase64(file): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  getNowDate(date: any, format = '') {
    const nd = date;
    let newdate = '';
    const mm = nd.getMonth() + 1;
    const dd = nd.getDate();
    const yy = nd.getFullYear();

    let mms = mm.toString();
    let dds = dd.toString();
    if (mm < 10) {
      mms = '0' + mm;
    }
    if (dd < 10) {
      dds = '0' + dd;
    }
    const yys = yy.toString();

    switch (format) {
      case 'valueOf':
        newdate = nd.valueOf();
        break;

      case 'dd-mm-yyyy': // 22/03/2018
        newdate = nd.toLocaleDateString('en-GB');
        break;

      case 'dd/mm/yyyy':
        newdate = dds + '/' + mms + '/' + yys;
        break;

      case 'yyyy-mm-dd': // 2018-03-22
        newdate = yys + '-' + mms + '-' + dds;
        break;

      case 'yyyymmdd':
        newdate = yys + mms + dds;
        break;

      case 'dd.mm.yyyy':
        newdate = dds + '.' + mms + '.' + yys;
        break;

      case 'dd':
        newdate = dds;
        break;

      case 'mm':
        newdate = mms;
        break;

      case 'yyyy':
        newdate = yys;
        break;

      case 'mm/dd/yyyy':
        newdate = mms + '/' + dds + '/' + yys;
        break;

      case 'yyyy-mm':
        newdate = yys + '-' + mms;
        break;

      case 'HH:mm:ss':
        let hrr, min, sec;
        const hrs = nd.getUTCHours();
        const mins = nd.getUTCMinutes();
        const secs = nd.getUTCSeconds();

        hrr = hrs < 10 ? '0' + hrs.toString() : hrs.toString();
        min = mins < 10 ? '0' + mins.toString() : mins.toString();
        sec = secs < 10 ? '0' + secs.toString() : secs.toString();

        newdate = hrr + ':' + min + ':' + sec;
        break;

      default:  // 22/03/2018, 11:31:48
        newdate = nd.toLocaleString('en-GB');
        break;
    }
    return newdate;
  }

  getUTCDate(date: any, format = '') {
    let finaldate = new Date();
    switch (format) {
      case 'UTC-dt':
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth();
        const dat = date.getUTCDate();
        const hrs = date.getUTCHours();
        const mins = date.getUTCMinutes();
        const sec = date.getUTCSeconds();
        finaldate = new Date(year, month, dat, hrs, mins, sec);

        break;

      default:
        finaldate = date;
        break;

    }
    return finaldate;
  }

  getLastDayofMth(oridate: any) {
    const year = this.getNowDate(oridate, 'yyyy');
    const month = this.getNowDate(oridate, 'mm'); // January
    // console.log(month);
    const d = new Date(Number(year), Number(month), 0).getDate();
    return d; // return 31 in number
  }

  delay(ms: number) {
    return new Promise<void>(function (resolve) {
      setTimeout(resolve, ms);
    });
  }

  checkHpFormat(phoneCode, hp, countryCode = 'MY'): string {
    let newHp = '';
    switch (countryCode) {
      case 'MY':
        if (hp.startsWith(phoneCode)) {
          newHp = hp;
        } else if (hp.startsWith('0')) {
          newHp = '+6' + hp;
        } else if (hp.startsWith('1')) {
          newHp = phoneCode + hp;
        } else if (hp.startsWith('6')) {
          newHp = '+' + hp;
        }
        break;
    }
    return newHp;
  }

  checkEmailFormat(email): boolean {
    // const check_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const check_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!check_email.test(email)) {
      return false;
    }
    return true;
  }

}
