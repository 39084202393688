import {Injectable} from '@angular/core';
import {WebapiService} from '../webapi/webapi.service';
import {SearchClass} from '../../models/search';
import {PinInfoClass} from '../../models/moneypin';

@Injectable({
  providedIn: 'root'
})
export class MoneyPinService {

  constructor(private apiService: WebapiService) {
  }

  getPinInfo(param: SearchClass): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = 'moneypin/getRemitInfo';
      const body = {
        pin_number: param.keyword1,
        mobileno: param.keyword2
      };
      // console.log(body);
      this.apiService.createURLEncodedService(url, body).then((data) => {
        // console.log(data);
        resolve(data);
      }).catch((err) => {
        // console.log(err);
        reject(err);
      });
    });
  }

  getReceiptInfo(param: PinInfoClass): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = 'moneypin/getReceiptInfoByTrxID';
      const body = {
        transaction_id: param.pin_redeem_tranx_id
      };
      this.apiService.createURLEncodedService(url, body).then((data) => {
        // console.log(data);
        resolve(data);
      }).catch((err) => {
        // console.log(err);
        reject(err);
      });
    });
  }

  getReceiptAddInfo(param: PinInfoClass): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = 'moneypin/getReceiptAddInfoList';
      const body = {
        transaction_id: param.pin_redeem_tranx_id
      };
      this.apiService.createURLEncodedService(url, body).then((data) => {
        // console.log(data);
        resolve(data);
      }).catch((err) => {
        // console.log(err);
        reject(err);
      });
    });
  }

  getOtherCountryPinInfo(param: SearchClass): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = 'moneypin/getOtherCountryMPinInfo';
      const body = {
        pin_no: param.keyword1,
        sender_mobile: param.keyword2,
        trxid: param.intkeyword1
      };

      this.apiService.createURLEncodedService(url, body).then((data) => {
        // console.log(data);
        resolve(data);
      }).catch((err) => {
        // console.log(err);
        reject(err);
      });
    });
  }
}
