import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {Error404Component} from './pages/error404/error404.component';

import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {MainmoneypinComponent} from './pages/mainmoneypin/mainmoneypin.component';
import {MoneypinreceiptComponent} from './pages/moneypinreceipt/moneypinreceipt.component';
import {OtherCountryMainMoneypinComponent} from './pages/other-country-main-moneypin/other-country-main-moneypin.component';
import {OthermoneypinreceiptComponent} from './pages/othermoneypinreceipt/othermoneypinreceipt.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: MainMenuComponent, runGuardsAndResolvers: 'always'},
  {path: 'indo2', component: MainmoneypinComponent, runGuardsAndResolvers: 'always'},
  {path: 'receipt', component: MoneypinreceiptComponent, runGuardsAndResolvers: 'always'},
  {path: 'other', component: OtherCountryMainMoneypinComponent, runGuardsAndResolvers: 'always'},
  {path: 'oreceipt', component: OthermoneypinreceiptComponent, runGuardsAndResolvers: 'always'},
  {path: '**', component: Error404Component, runGuardsAndResolvers: 'always'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    enableTracing: false,
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
