import {Injectable} from '@angular/core';
import {HttpClientModule, HttpClient, HttpHeaders, HttpParams, HttpErrorResponse} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {APPCONFIG} from '../../appconfig/app.constant';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebapiService {
  headers: HttpHeaders;
  options: any;
  appconfig = APPCONFIG;

  constructor(private http: HttpClient, private router: Router) {
  }

  createURLEncodedService(url: string, body: any): Promise<any> {
    const domainpath = window.location.origin;
// const basedurl = 'http://localhost:8765/api/';
    const basedurl = domainpath + this.appconfig.environment === 'dev' ? this.appconfig.basedUrl.dev : this.appconfig.basedUrl.prod;
    // console.log(basedurl);
    // if (token === true) {
    //   this.headers = new HttpHeaders({
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Accept': 'application/json',
    //     'x-access-token': user.token
    //   });
    // } else {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    });

    // }
    this.options = {
      headers: this.headers
    };

    const param = this.serializeData(body);
    // console.log(param);
    // console.log(basedurl + url);
    return this.http
      .post(basedurl + url, param, this.options)
      .toPromise()
      .then((result: any) => {
        // console.log(result);
        // if (result && result.statusCode && result.statusCode === 403) {
        //   swal({
        //     icon: 'error',
        //     text: 'Session Expired! Please login to proceed.'
        //   }).then(() => {
        //     this.terminator.terminate();
        //   });
        // }
        // if (result) {
        //   return JSON.parse(result);
        // }
        return result;
      })
      .catch(this.handleError);
  }

  getRequest(url: string) {
    this.http.get(url).subscribe(data => {
        console.log(data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('error occured.');
        } else {
          console.log('Server-side error occured.');
        }
      });
  }

  postRequest(url: string, body: any) {
    this.http.post(url, body, this.options)
      .subscribe(
        res => {
          console.log(res);
        },
        err => {
          console.log('j');
        }
      );
  }

  // createService(url: string, param: any): Promise<any> {
  //   const body = this.serializeData(param);
  //   return this.http
  //     .post(url, body, this.options)
  //     .toPromise()
  //     .then((result) => {
  //       console.log(result);
  //       return this.extractData(result);
  //     })
  //     .catch(this.handleError);
  // }
  //

  serializeData(data): string {
    // If this is not an object, defer to native stringification.
    // if (!data.isObject()) {
    //   return ( ( data == null ) ? '' : data.toString() );
    // }
    const buffer = [];
    // Serialize each key in the object.
    for (const name in data) {
      if (!data.hasOwnProperty(name)) {
        continue;
      }
      const value = data[name];
      buffer.push(
        encodeURIComponent(name) +
        '=' +
        encodeURIComponent((value == null) ? '' : value)
      );
    }
    // Serialize the buffer and clean it up for transportation.
    const source = buffer
      .join('&')
      .replace(/%20/g, '+')
    ;
    return (source);
  }

  // private extractData(res: Response) {
  //   const body = res.json();
  //   if (typeof body.logout !== undefined) {
  //     this.terminator.terminate(body);
  //   }
  //
  //   return body || {};
  // }

  private handleError(error: any): Promise<any> {
    // console.log(error);
    // console.log('An error occurred' + error);
    return Promise.reject(error.message || error);
  }

  getIpAddress(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('https://jsonip.com')
        .subscribe(data => {
          resolve(data);
        });
    });
  }

}
