export class MoneyPinReceiptClass {
  transactionID: number;
  transactionDate: any;
  ringgitMalaysia: number;
  malaysia_currency_code: string;
  exchange_rate: string;
  exchange_rate2: string;
  indo_rupiah: number;
  indo_currency_code: string;


  money_pin_charge: number;
  phone: string;
  card_no: string;

  subtotal: number;  // ringgit malaysia + money pin charge

  remit_name: string;
  recipient_name: string;
  bank: string;
  bank_accNo: string;
}

export class PinInfoClass {
  pin_idx: number;
  pin_creation_date: any;
  pin_expiry_date: any;
  pin_merchant_crc_id: number;
  pin_number: string;
  pin_type: number;
  pin_sequence_no: number;
  pin_sequence6_no: number;
  pin_random_no: number;
  pin_owner_no: string;
  pin_tranx_id: number;
  pin_amount: number;
  pin_mdr: number;
  pin_redeem_tranx_id: number;
  pin_redeem_date: any;
  pin_redeem_HP: any;
  pin_status: number;
  pin_product_id: number;
  pin_flag: number;
  card_id: string;
  pin_issue_channel: string;
  ref_idx: any;

  c_number: string;
  pin_amount2: number;
  PinType_Desc: string;
  PinStatus: number;
  PinStatus_desc: string;
  RedeemTxnId: number;

  showButton = true;
  noButtonMsg = '';
}

export class OtherCounrtyMPinClass {
  trans_id: number;
  pinno: string;
  CreatedDatetime: any;
  SenderName: string;
  SenderMobile: string;
  ReceiverName: string;
  BankID: string;
  BankAccountNumber: string;
  mm_charged_amt: number;
  mm_trans_fee: number;
  mm_exchange_rate: number;
  payout_amt: number;
  payout_currency: string;
  invoice_no: string;
  tax_amt: number;
  status: string;
  country: string; // receiver country
  remit_provider_id: number;
  remit_provider_desc: string;
  req_source: number;
  req_source_desc: string;
  showButton = true;
  noButtonMsg = '';
  BankName = '';
  SenderCardNo : string;
}
